/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


jQuery(document).ready(function($) {
  var $postgrid = $('.post-grid').packery({
    itemSelector: '.post-box'
  });
  // layout Packery after each image loads
  $postgrid.imagesLoaded().progress( function() {
    $postgrid.packery();
  });

  var $grid = $('.image-grid').packery({
    itemSelector: '.image'
  });
  // layout Packery after each image loads
  $grid.imagesLoaded().progress( function() {
    $grid.packery();
  });
  
  var curScrollPos = 0;
  var checkScroll = true;

  function fixGrid() {
    $('.image').removeClass('image-active');
    $('body').removeClass('lightbox');
    // back to small, shiftLayout back
    $grid.packery('shiftLayout');
  }

  $grid.on( 'click', '.image', function( event ) {
    var $item = $( event.currentTarget );
    checkScroll = false;
    
    // $('.image').removeClass('image-active');
  
    // change size of item by toggling large class
    
    if ( !$item.is('.image-active') ) {
      $('body').addClass('lightbox');
  
      $('.image').removeClass('image-active');
      $item.toggleClass('image-active');
  
      // fit large item
      $grid.packery();
      $grid.packery( 'fit', event.currentTarget );
    } else {
      fixGrid();
    }
  
  });
  
  $grid.on( 'fitComplete',
    function( event, item ) {
      
      // console.log( 'Packery fit', item.element );
      var scrollDiff = parseInt( item.position.y ) + parseInt( item.element.offsetParent.offsetTop ) + parseInt( item.element.closest('.panel').getClientRects()[0].top
  ) - ( (window.innerHeight - item.element.offsetHeight) / 2);
  
      if (scrollDiff) {
        
        var scrollPos = $('html,body').scrollTop();
/*         if (jQuery.browser.safari) { 
          scrollPos = $('body').scrollTop(); 
        } */

        curScrollPos = scrollPos + scrollDiff;
/*        if (jQuery.browser.safari) {
          $('body').animate({
            scrollTop : curScrollPos
          }, 200, function() {
            checkScroll = true;
          });
        } else {
          $('html,body').animate({
            scrollTop : curScrollPos
          }, 200, function() {
            checkScroll = true;
          });
        } */

        $('html,body').animate({
          scrollTop : curScrollPos
        }, 200, function() {
          checkScroll = true;
        });
      }
    }
  );

  $(document).keydown(function(e) {
    checkScroll = false;
    var $item = $('.image.image-active' );
    var $target;

    if ( $item ) {
      if (e.keyCode === 37) {
        $('body').addClass('lightbox');
        $('.image').removeClass('image-active');
        $target = $item.prev('.image');
        console.log($target);

        if ($target.length > 0) {
          $target.addClass('image-active');
          // fit large item
          $grid.packery();
          $grid.packery( 'fit', $target );
        } else {
          fixGrid();
        }
      }

      if (e.keyCode === 39) {
        if ( $item ) {
          $('body').addClass('lightbox');
          $('.image').removeClass('image-active');
          $target = $item.next('.image');

          if ($target.length > 0) {
            $target.addClass('image-active');
            // fit large item
            $grid.packery();
            $grid.packery( 'fit', $target );
          } else {
            fixGrid();
          }
        }
      }
    }
  });

  $(document).on('scroll', function() {
    var scrollPos = $(window).scrollTop();
    
    if ( scrollPos > 200 ) {
      $('.panel').each(function(k, v) {
        if( scrollPos > $(this).position().top + 400 ) {
          $(this).addClass('concat');
        } else {
          $(this).removeClass('concat');
        }
      });
    } else {
      $('.panel').removeClass('concat');
    }

    if (checkScroll){
      var threshold = 50; // $(window).height();
      // console.log(scrollPos + ' ' + (curScrollPos+threshold) + ' ' + (curScrollPos-threshold)  );
      if ( scrollPos > curScrollPos + threshold || scrollPos < curScrollPos - threshold ) {
        fixGrid();
      }
    }
  });
  
  $(document).on('click', 'p a', function(e){
    var href = $(this).attr('href');
    if (href.substring(0,1) === "#"){
      e.preventDefault();
      var scrollDest = $(href).position().top;
      console.log('goto', scrollDest);
      $('html,body').animate({
        scrollTop : scrollDest
      }, 1000);
    }
  });

  $('.reference li').on('click', function(e){
    var url = $(this).data('image');
    var rel = $(this).data('rel');
    $('.image-placeholder').removeClass('active');
    $('.reference li').removeClass('active');
    $(this).addClass('active');
    $(rel).addClass('active');

    $('html,body').animate({
      scrollTop : $('section.reference .image').position().top - 150
    }, 500);
/*     $(this).find('.reference').hide();

    if(url && rel){
      var imagehtml = "<img src='"+ url +"' />";
      $(rel).html(imagehtml);
    } */
  });
  
  jQuery("#contact-form").submit(function(e) {
    e.preventDefault();
  
    var subject   = jQuery("#contact-form #subject").val();

    var toEmail   = jQuery("#contact-form #toEmail").val();
    var toName    = jQuery("#contact-form #toName").val();
    var fromName   = jQuery("#contact-form #fromName").val();
    var fromEmail = 'adminstrator@detnyesort.dk'; 

    var success   = jQuery("#contact-form #success").val();
    var error     = jQuery("#contact-form #error").val();

    var content   = "";
    var fields    = jQuery("#contact-form").serializeArray();
    jQuery.each(fields, function(k,v) { 
      content += v.name +": "+ v.value +"<br>";
    });

    jQuery.ajax({
        type: "POST",
        url: "https://mandrillapp.com/api/1.0/messages/send.json",
        data: {
          'key': 'GWY1LGmmhPV6gtvKMEkHWA',
          'message': {
            'from_email': fromEmail,
            'from_name': fromName,
            'subject': subject,
            'html': content,
            'to': [{
              'email': toEmail,
              'name': toName,
              'type': 'to'
            }],
            'bcc': 'michael@detnyesort.dk'
          }
        }
      })
      .done(function(response) {
        jQuery("#contact-form .alerts").append('<br/><div class="alert alert-success" role="alert">'+success+'</div>');
        document.getElementById("contact-form").reset();
      })
      .fail(function(response) {
        jQuery("#contact-form .alerts").append('<br/><div class="alert alert-warning" role="alert">'+error+'</div>');
      });
    return false; // prevent page refresh
  });

  jQuery('.toggle-menu').on('click', function() {
    jQuery('body').toggleClass('menu-active');
  });
  
}); 

// Lazyload images
document.addEventListener("DOMContentLoaded", function() {
  var lazyloadImages = document.querySelectorAll("img.lazy");
  var lazyloadThrottleTimeout;
  
  function lazyload() {
    if(lazyloadThrottleTimeout) {
      clearTimeout(lazyloadThrottleTimeout);
    }    
    
    lazyloadThrottleTimeout = setTimeout(function() {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function(img) {
            if(img.offsetTop < (window.innerHeight + scrollTop)) {
              img.src = img.dataset.src;
              img.classList.remove('lazy');
            }
        });
        if(lazyloadImages.length === 0) { 
          document.removeEventListener("scroll", lazyload);
          window.removeEventListener("resize", lazyload);
          window.removeEventListener("orientationChange", lazyload);
        }
    }, 20);
  }

  lazyload();
  
  document.addEventListener("scroll", lazyload);
  window.addEventListener("resize", lazyload);
  window.addEventListener("orientationChange", lazyload);
});
